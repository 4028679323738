import React, { useState } from "react"
import "../../theme/type.scss"
import CustomFooter from "../../components/CustomFooter"
import GdprCookies from "../../components/GdprCookies"
import HeaderLayout from "../../components/layout/HeaderLayout"
import "../../theme/layout.scss"
import "../../theme/david.scss"
import { Grid } from "@material-ui/core"
import ModalSuscribe from "../../components/layout/ModalSuscribre"


const SemanalBolsaMundo = ({ data }) => {
  //functions modal suscribe
  const [open, setOpen] = useState(false)

  function openSuscribe() {
    setOpen(true)
  }

  function closeSuscribe() {
    setOpen(false)
  }


  return (
    <>
      <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe} />
      <div className="wrap-content">
        <div className="header--section">
          <h2 className="title--section">DISCLOSURE</h2>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p><strong>El presente se utiliza en todaslas publicaciones de reportes elaborados por el área de Análisis
              de
              Vector
              Casa de Bolsa, S.A. de C.V., aplica para todos los contenidos de estos
              documentos y se incluye al final de los mismos</strong></p>.
            <h3>Certificación de los Analistas</h3>
            <p>Los Analistas que elaboran las recomendaciones respecto de los valores referidos,son: Marco Antonio
              Montañez
              Torres(Análisisfundamental, mmontane@vector.com.mx, ext. 3706), Gerardo Cevallos Orvañanos (Análisis
              fundamental, gcevallo@vector.com.mx, ext. 3686), Marcela Muñoz Moheno
              (Análisis fundamental, mmunoz@vector.com.mx, ext. 3156), Marco
              AntonioCastañedaCruz(Analistajr.,<a target="_blank" rel="noreferrer" href="mailto:mcastane@vector.com.mx"
                                                  style={{
                                                    fontWeight: 700,
                                                    color: "#FF6600",
                                                  }}>mcastane@vector.com.mx</a>,ext.3015),GeorginaMuñizSánchez(Análisistécnico,gmuniz@vector.com.mx,ext.3777)yRicardoBravoGómez(Analista
              de renta fija, <a target="_blank" rel="noreferrer" href="mailto:rbravo@vector.com.mx"
                                style={{ fontWeight: 700, color: "#FF6600" }}>rbravo@vector.com.mx</a>,
              ext. 3572), los cuáles hacen constar que los puntos de vista que se
              expresan en este documento son reflejo fiel de su opinión personalsobre la(s) compañía(s) o empresa(s)
              objeto de este reporte, de sus afiliadas y/o de los valores que ha emitido y
              están basados en información pública donde se distingue la información financiera histórica de la estimada
              o proyectada por el área de análisis, así como sus principalessupuestos.
              Asimismo,manifiestan que no han recibido nirecibirán compensación directa o indirecta alguna a cambio de
              expresarsu opinión en algún sentido específico en este documento. Elseñor
              MarcoAntonioMontañez Torres(mmontane@vector.com.mx, ext. 3706) esla persona
              encargada,ensucarácterdeDirectordeAnálisisyEstrategia,delarevisióny aprobacióndelosreportesdeanálisis.</p>

            <p>El grupo de especialistas en economía se integra por: Rodolfo Navarrete Vargas (Chief Economist Officer,
              rnavarre@vector.com.mx, ext. 3646), Luis Adrián Muñiz (Análisis económico
              nacional,lmuniz@vector.com.mx,ext.3256)yAlejandroArellanoBest(Análisiseconómicointernacional,aarellan@vector.com.mx,ext.3154).
            </p>
            <h3>Declaracionesrelevantes</h3>
            <p>Conforme a las leyes vigentes y los manuales internos de procedimientos, los Analistas tienen permitido
              mantener inversiones en acciones o valores emitidos por empresas que cotizan en las bolsas de valores
              y que pueden ser objeto del presente reporte.
              Sin embargo,los Analistas Bursátiles tienen que observar ciertas reglas que regulan su participación en el
              mercado con el fin de prevenir, entre otras cosas,la utilización de información privilegiada en su
              beneficio y
              evitar conflictos de interés.</p>
            <h3>Remuneración de Analistas</h3>
            <p>La remuneración de los analistas se basa en el desempeñode sus actividades de manera correcta, adecuada
              y objetiva y dicha remuneración se determina con base en la rentabilidad general de la Casa de Bolsa y sus
              filiales y en el desempeño individual de los analistas. Sin embargo, los
              inversionistas deberán advertir que los analistas no reciben pago directo o compensación por transacción
              específica alguna en financiamiento corporativo, banca de inversión o en las demás
              áreasde negocio.</p>
            <h3>Actividades de las áreas de negocio durante los últimos doce meses</h3>
            <p>Vector Casa de Bolsa y sus filiales, a través de sus áreas de negocio, brindan servicios que incluyen,
              entre otros,los correspondientes a financiamiento corporativo, colocaciones, banca
              de inversión y servicios de corretaje, a un gran número de empresas en México y en el extranjero.Es
              posible que hayan prestado,estén prestando o en el futuro brinden algún servicio
              como los mencionados a las compañías o empresas objeto de este reporte. Vector Casa de Bolsa o sus
              filiales reciben una remuneración por parte de dichas corporaciones en
              contraprestación de los servicios antes mencionados.
            </p>
            <h3>Tenencia de valores y otras revelaciones</h3>
            <p>Vector Casa de Bolsa o sus filiales no mantienen inversiones, al cierre del último trimestre, directa o
              indirectamente,en valores o instrumentos financieros derivados, cuyo subyacente
              sean valores, objeto de recomendaciones, que representen el 1% omás de su cartera de valores o portafolio
              de inversión o el 1% de la emisión o subyacente de los valores emitidos.
              Algunos miembros del Consejo(propietarios y/osuplentes),directores generales y directivos del nivel
              inmediato inferior a éste de Vector Casa de Bolsa o sus filiales,fungen con alguno
              de dichos caracteres en las emisoras que pueden ser objeto de análisis de algunas emisoras.</p>
            <h3>Guía para las recomendaciones de inversión</h3>
            <p>La Recomendación de Inversión está relacionada con el Rendimiento Total estimado del valor para el cierre
              de
              año,o cuando se indique para los próximos doce meses. El rendimiento total requerido para una
              recomendación depende de su relación con el rendimiento esperado para el Benchmark
              (S&P/BMVIPC),sin embargo,este criterio puede ser modificado por el analista de acuerdo a su evaluación o
              percepción delriesgo específico de la inversión. Como tasa libre
              de riesgo,se considera el rendimiento de los bonos soberanos de largo plazo.
              Aunque este documento ofrece un criterio general de inversión, invitamos al lector a que busque asesorarse
              con sus propios Consultores o Asesores Financieros, con el fin de
              considerar si algún valor de los mencionados en el presente reporte se ajusta a sus metas de inversión,
              perfil de
              riesgo y posición financiera.</p>
            <p><strong>COMPRA</strong>: Rendimiento esperado en la emisora mayor al Benchmark. <br />
              <strong>MANTENER</strong>: Rendimiento esperado en la emisora menor al esperado para el Benchmark y mayor
              a la tasa libre de riesgo. <br />
              <strong>VENTA</strong>: Rendimiento esperado en la emisora negativo o menor a la tasa libre de riesgo.
            </p>
            <h3>Determinación del valor intrínseco</h3>
            <p>Para el cálculo del valor intrínseco a 12 meses(VI 12M)estimados para los valores, los analistas utilizan
              una combinación de metodologías generalmente aceptadas entre los analistas
              financieros,incluyendo de manera enunciativa,más no limitativa,el uso de métodos absolutos y relativos,
              aplicable en cada caso específico.No se puede dar garantía alguna de que se
              vayan a lograr los valores intrínsecos calculados para los valores por los analistasde Vector Casa de
              Bolsa, ya que esto depende de una gran cantidad de diversos factores endógenos y
              exógenos que afectan el desempeño de la empresa emisora, el entorno en el que se desempeña e influyen en
              las tendencias del mercado de valores en el que cotiza. Es más, el
              inversionista debe considerar que el precio de los valores o instrumentos puede fluctuar en contra de su
              interés y ocasionarle la pérdida parcial y hasta total del capital invertido.
            </p>
            <p>Datos al 30 de junio de 2024</p>
            <table className={"table-bordered table-striped"}>
              <thead className={"bg-head"}>
              <tr>
                <th>Vector Casa de Bolsa, S.A. de C.V</th>
                <th>Compra</th>
                <th>Mantener</th>
                <th>Venta</th>
                <th>En Revisión</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Distribución de las recomendaciones fundamentales de Análisis</td>
                <td>20</td>
                <td>12</td>
                <td>0</td>
                <td>0</td>
              </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
      <CustomFooter openModal={openSuscribe} />
      <GdprCookies />
      {
        open && <ModalSuscribe closeModal={closeSuscribe} />
      }
    </>
  )
}

export default SemanalBolsaMundo
